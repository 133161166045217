import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">Why should you start</span>
               DeFi Platform Development?
              </h2>
              <p className="pharagraph head">DeFi Platform Development Service will help you enter the crypto ecosystem to generate an ample amount of profits in a short period. Here are some notable reasons to develop a DeFi platform.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/why-should-you-start-defi-development-company.svg" alt="Why Should Start DeFi Development Company image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph"  >Although the age-old finance systems have served well so far, they are quite limiting because they are controlled by a select few institutions.</li>
                <li className="pharagraph" >DeFi applications are liberating because they allow people to exchange valuables directly with each other without involving brokers and conventional finance ecosystems.
                </li>
                <li className="pharagraph" >People can transfer valuable real-world assets through non-fungible tokens using DeFi applications and DeFi platforms.</li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould