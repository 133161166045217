import React from 'react'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'


class BannerSection extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">DeFi Development Company</h1>
            <p className="sub-heading">Premier decentralized finance development company.</p>
            <p className="sub-small">Own the next-generation finance system. Replace the conventional finance system with a decentralized platform.</p>
          </div>
          <div className="quick text-center d-none d-md-block">
            <img width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/defi-development-company.png" alt="DeFi Development Company image1" />
          </div>
          <p className="pharagraph">A permissionless blockchain-based system loved by cryptocurrency enthusiasts.
          </p>
          <div className="text-center mt-4">
            <ButtonStartfree />
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection