import React from 'react'




class Whatis extends React.Component {


  render() {

    return (
      <section className="pt-0">
        <div className='gray-bg'>
          <div className="container">
                <h2 className="heading-h2 text-center">DeFi Development Company - <span className='bluecolor'>Coinsclone</span></h2>
                <p className="pharagraph text-center mb-0 mw1030">Coinsclone is a leading DeFi development company that helps you to elevate your cryptocurrency business. Our developers will ignite your ideas into reality at an affordable cost. We focus on building Defi platforms that operate without intermediaries and provide crypto users with a peer-to-peer trading experience. Our development team offers numerous DeFi development services that cater user’s needs. 
                </p>
          </div>
        </div>
      </section>
      
    )
  }
}

export default Whatis