import React from 'react'




class UsecaseOf extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section className="usecase mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Cases</span> of </span>
              DeFi Platform Development</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/usecase.svg" alt="Use Case of DeFi Development Company image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>DeFi Exchange -</b> Our DeFi development services allow them to create their crypto exchange for trading purposes. We facilitate potential smart contracts to automate trading, staking, and lending.
              </p>
              <p className="pharagraph" ><b>Asset Management -</b> We develop DeFi platforms that encourage individuals to maintain their digital assets by themselves. Users can easily track the progress of the virtual assets.
              </p>
              <p className="pharagraph" ><b>Decentralized Autonomous Organization (DAO) -</b>  Our blockchain-powered DeFi platforms help tackle major financial challenges like fund generation, governance establishment, and resource maintenance.
              </p>
              <p className="pharagraph" ><b>Yield Farming -</b> Here, crypto users can lock or stake their crypto assets securely for passive income. The rewards are evenly distributed by smart contracts automatically.
              </p>
              <p className="pharagraph" ><b>DeFi Insurance -</b> Unlike the traditional DeFi insurance system, our DeFi insurance services help users reduce the middleman cost and accuracy for staking.
              </p>
              <p className="pharagraph" ><b>Decentralized Stablecoins -</b> DeFi stablecoins are widely used by a vast number of crypto users who want to earn revenue from their real-world assets.
              </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default UsecaseOf