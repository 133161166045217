import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'



const WhyChoose = () => {


  return (
    <section className="pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">What makes Coinsclone </span>the best DeFi Development Company?</h2>
              <p className="pharagraph">Coinsclone is a leading reputed DeFi development company that comprises knowledgeable developers, technicians, and designers. We helped many newbie startups and entrepreneurs to succeed in the crypto universe. We possess a core tech team with superior exposure in creating a DeFi platform development. Our team has the potential with 8+ years of excellence in building DeFi platforms that are highly transparent, and immutable.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/what-make-defi-development.webp"
                alt="DeFi Development Company image1"
                placeholder='none'
                className='mt-3 mb-3'
                width={473}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">We offer top-level DeFi development services on time at an affordable cost that suits the budget of startups and entrepreneurs. You can tailor and optimize the platform that meet your business specifications and requirements. We assure to provide DeFi development services from concept to creation</p>
              <ul className='mt-3'>
                <li>Potential Feature Integration</li>
                <li>Prompt Delivery</li>
                <li>360 Degree Support</li>
                <li>Customizable Solutions</li>
                <li>Quality over Quantity</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
  )
}

export default WhyChoose