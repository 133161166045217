import React from 'react'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">Our <span className="bluecolor">DeFi Development Services</span></h2>
              <p className='text-center'>Deploy the next-gen digitalized financial system with our vast number of DeFi development services.</p>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">DeFi Token Development</h3>
                <p className="pharagraph">Our team of developers are experts in developing DeFi tokens for various business purposes. We have enough experience in developing DeFi tokens that have enriched features and smart contracts for seamless automation.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Defi-token-development.png" alt="DeFi token development image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Defi-apps-development.png" alt="DeFi Smart Contract Development image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">DeFi Smart Contract Development</h3>
                <p className="pharagraph">We are experts in creating completely self-executing smart contracts for DeFi platforms and dApps. Our DeFi smart contracts can enable fast and secure transactions that provide accurate results.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Decentralized Exchange Development</h3>
                <p className="pharagraph">We are experienced in developing DEX platforms that eliminate third parties or intermediaries. Our Coinsclone team ensures unique feature integration, security protocols, and plugins.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Defi-insurance-platform-development.png" alt="Decentralized Exchange Development image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/DeFi-Lending-Platform-Development.png" alt="DeFi Lending Platform Development image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">DeFi Lending/Borrowing Development </h3>
                <p className="pharagraph">Our DeFi lending & borrowing platform helps users borrow and lend crypto tokens without third-party interference. Our DeFi lending platform possesses the most secure, immutable, and transparent nature.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">DeFi Staking Development</h3>
                <p className="pharagraph">We help crypto startups develop DeFi staking platforms where users can stake their cryptocurrencies with utmost security. Our developers are well-versed in creating a platform with excellent features and plugins.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/DeFi-Lottery-System-Development.png" alt="DeFi Staking Development image1" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures