import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Core Features</span> of our</span>
            DeFi Development Services</h3>
            <p className='text-center'>We create every pixel of the website with exponential features that catch the eyes of crypto users.</p>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                 Unhidden Protocols
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Smart Contract Implementation
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                 Interoperability
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Pseudonymous Transactions
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Highly Secure
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Automation
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Unhidden Protocols
                        </h4>
                        <p className="pharagraph">We prioritize providing protocols for higher transparency and security. This helps to ensure the decentralized finance ecosystem's transactions, staking, and lending process. Users can check through them without any restrictions.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Unhidden Protocols.svg" alt="Unhidden Protocols image1" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Smart Contract Implementation</h4>
                        <p className="pharagraph">Our developer team concentrates more on implementing smart contracts seamlessly. Our smart contract helps the platforms to execute the crypto transactions faster and it also enhances the efficiency.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Smart Contract Implementation.png" alt="Smart Contract Implementation image1" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4"> Interoperability</h4>
                        <p className="pharagraph">Our DeFi platforms are designed with an interoperable nature where it enables smooth communication and data exchanges. It fosters a cohesive DeFi ecosystem and enhances the user experience.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Interoperability.png" alt="Interoperability image1" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Pseudonymous Transactions
                        </h4>
                        <p className="pharagraph">DeFi platforms are meant for higher privacy and anonymous nature. Users can execute the transactions seamlessly to trade cryptocurrencies with confidentiality. This empowers the user’s trust for transactions.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Pseudonymous Transactions.svg" alt="Pseudonymous Transactions image1" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Highly Secure</h4>
                        <p className="pharagraph">Our developer team implements higher security protocols for secure crypto transactions. Our DeFi development services are advanced to protect user data, funds, and personal information.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Highly Secure.png" alt="Highly Secure image1" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Automation</h4>
                        <p className="pharagraph">Coinsclone integrates the DeFi platform to automate transactions and reward distribution for crypto users. Our DeFi development services also reduce the risk of errors and challenges.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defidevelopment/Automation.svg" alt="Automation image1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures