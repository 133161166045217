import React from 'react'


const BenifitsOf = () => {

  return (
    <section className="cryptoex typeof defidev pt-100 mb-0">
        <div className="container">
          <div className="row">
              <h2 className="heading-h2 text-center"><span className="bluecolor">Real-Life Benefits</span> Of DeFi Development
              </h2>
              <p className="text-center">
              Our DeFi development services offer numerous benefits for startups looking to create their DeFi platform.
              </p>
          </div>
          <div className='row popular we-offer'>
              <div className='col-md-12 col-lg-3'>
                  <h3>Self-Execution</h3>
                  <p className='pharagraph'>DeFi protocols are highly preferred and used for their self-executing nature. This is the biggest perk where errors, delays, and miscommunications are avoided.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Reduced Transaction Cost</h3>
                  <p className='pharagraph'>Compared to the traditional financial charges and costs, DeFi platforms charge low costs for crypto trading. Unnecessary prices and overhead charges for transactions are also lowered.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Faster Settlement</h3>
                  <p className='pharagraph'>DeFi platforms automate the transactions within a few seconds which helps to grab user attention. This enhances the users to access and deploy capital more rapidly.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Higher Liquidity</h3>
                  <p className='pharagraph'>The liquidity of the DeFi platforms increases rapidly through various DEX platforms and DEX aggregators. Higher liquidity enables market efficiency and it benefits investors and traders.</p>
              </div>
          </div>
        </div>
    </section>
  )
}

export default BenifitsOf